<template>
    <div class="manageSystem">
      <div class="boxContent">
        <!-- 头部导航区域 -->
        <div class="navbar main-container">
          <Navbar :itemIndex="'9'" />
        </div>
      </div>
      <!-- banner区域 -->
      <div class="barnner-bg bg-pic">
        <div class="banner main-container">
          <div class="banner-title">
            管理系统
          </div>
          <div class="see-more">
            资金交易是银行资金业务的发起点，也是华炫鼎盛解决方案的入口，华炫鼎盛在银行与分行间提供结售汇与外汇交易报价平台；在分行与对公、对私客户间提供代客交易平台；在银行与银行间外汇与同业市场里提供做市商报价平台。
          </div>
        </div>
      </div>
      <div class="tab-box">
        <ul class="">
          <li @click="handleTab(0)" :class="bgColor == 0 ? 'bg-color' : ''">资金一体化业务系统</li>
          <li @click="handleTab(1)" :class="bgColor == 1 ? 'bg-color' : ''">基金托管报文交换系统</li>
          <li @click="handleTab(2)" :class="bgColor == 2 ? 'bg-color' : ''">筹资发债管理系统</li>
          <li @click="handleTab(3)" :class="bgColor == 3 ? 'bg-color' : ''">流动性管理系统</li>
        </ul>
      </div>
      <!-- 资金一体化业务系统 -->
      <div v-show="tab0" class="foreign-exchange-quotation proxy-trading-platform">
        <div class="proxy-trading-box">
            <div class="proxy-trading-top">
              <div class="proxy-trading-left"><img src="@/assets/manageSystem/zjyt_img@2x.png" alt="" /></div>
              <div class="proxy-trading-right">
                <div class="proxy-trading-right-title">资金一体化业务系统</div>
                <div class="proxy-trading-right-content">资金一体化管理从交易员发起交易后驱动一连串的前、中、后台的业务处理，包含风险检查、合规检查、审批流程、交易下单、交易获取、交易证实、交易清结算以及交易核算等作业。</div>
              </div>
            </div>
            <div class="proxy-trading-center">
              <div class="benefit">应用华炫鼎盛代客交易平台，可以带给银行以下效益：</div>
              <ul>
                <li>
                  <img src="@/assets/manageSystem/zjyt_ico_1@2x.png" alt="" />
                  <div>实时信息反馈</div>
                  <span>所有业务处理的状态会立即反馈给前台，让交易员实时掌握交易情况与头寸状态，方便续做交易或进行决策，进而提升交易效率；</span>
                </li>
                <li>
                  <img src="@/assets/manageSystem/zjyt_ico_2@2x.png" alt="" />
                  <div>优化风控手段</div>
                  <span>所有交易在结算前都会进行风控检核，避免事后发生风险或合规问题时再进行补救;</span>
                </li>
                <li>
                  <img src="@/assets/manageSystem/zjyt_ico_3@2x.png" alt="" />
                  <div>数据无缝对接</div>
                  <span>交易数据在发起后便自动在系统中流转，过程中不需要风控人员、清算人员或核算人员再次输入，可以有效降低人工处理的工作量，并大幅减少作业风险的发生；</span>
                </li>
                <li>
                  <img src="@/assets/manageSystem/zjyt_ico_4@2x.png" alt="" />
                  <div>直通高效</div>
                  <span>交易通过风控检核之后，将自动根据银行预设或交易单上的汇路进行结算与清算，清算前会再次进行交易实证，证实无误便会自动根据银行会计核算要求出会计分录。上述流程绝大多数情况下均自动完成，不需人工介入处理。</span>
                </li>
              </ul>
            </div>
          </div>
        <div class="system-advantages-img">
            <div class="advantages">
              <div class="title">系统优势</div>
              <div class="system-advantages-box">
                <ul style="height: 148px;">
                  <li>市场行情</li>
                  <li style="height: 110px;">具备各种信息源的订阅接口，能自动接入市场行情与报价，包含路透、彭博、CMDS等； 风控前置: 在交易下单前进行各种市场风险与信用风险控制，引用风控部门设定的仓位限额、Delta限额、交易限额与对手授信额度进行交易检查；根据合规引擎检查交易合规性；根据绩效管理提示交易员日中止损等。通过风险前置，能在交易前就禁止不合规的交易，避免事后发现不合规或风险事件才进行反向对冲；</li>
                </ul>
                <ul>
                  <li>交易获取</li>
                  <li>可人工发起交易或是通过万能接口、云端互联网、人工补录等三种方式取得所有交易要素；</li>
                </ul>
              </div>
              <div class="system-advantages-box">
                <ul>
                  <li>交易结算</li>
                  <li>提供高参数化配置, 按照商品与生命周期的事件自动做会计核算并对接包括核心系统等行内其他财务系统；</li>
                </ul>
                <ul>
                  <li>交易核算</li>
                  <li>提供高参数化配置, 按照商品与生命周期的事件自动做会计核算并对接包括核心系统等行内其他财务系统；</li>
                </ul>
              </div>
              <div class="system-advantages-box">
                <ul>
                  <li>交易清算</li>
                  <li>按照交易对手汇路与交易要素, 智能判读产生清算报文；</li>
                </ul>
                <ul style="height: 104px;">
                  <li>风险管理</li>
                  <li style="height: 66px;">进行多项市场风险的计量与监控，包含市值、损益、止损、久期、凸性情景分析等，并采用参数法与模拟法计算资产VaR；根据额度与合规设置监控各类资产的信用风险，通过模型运算ECL, PD, CVA等信用风险值以及减值依据；</li>
                </ul>
              </div>
              <div class="system-advantages-box">
                <ul>
                  <li>数据分析</li>
                  <li>通过量价比较分析, 结合市场数据挖掘, 提供交易有效分析；</li>
                </ul>
                <ul>
                  <li>系统监控</li>
                  <li>提供系统平台上用户使用状况监控、服务监控、系统资源监控，确保每笔交易在清算、结算、核 算每一个环节的顺利且正确处理。</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="process-flow">
            <div class="process-flow-title">STP业务流程</div>
            <div class="process-flow-img">
              <img src="@/assets/manageSystem/stp_lct@2x.png" alt="" />
            </div>
          </div>
      </div>
      <!-- 基金托管报文交换系统 -->
      <div v-show="tab1" class="quotation-platform">
        <div class="quotation-platform-top">
          <div><img src="@/assets/manageSystem/jjtg_img@2x.png" alt="" /></div>
          <ul>
            <li>基金托管报文交换系统</li>
            <li>随著国内银行间市场对外开放、人民币走出国际，以及一带一路的政策指导下，银行的托管业务逐渐成为银行服务的一个新的亮点。而托管业务的核心就是SWIFT报文的交换与传送。</li>
            <li>华炫鼎盛SWIFT报文交换系统是银行与境外托管行及境外投资管理人之间信息交互的唯一通道，该系统肩负如下任务：信息接收后，进行解析、匹配、核对，分装，并实现统一的报文接入和发送接口。通过功能权限和数据权限实现业务流程灵活配置，支持托管业务流程改造。加强权限管理、差错预警、监督核查等方面的内容，提升风险防范能力。</li>
          </ul>
        </div>
        <div class="architecture-diagram">
          <div class="architecture-diagram-title">基金托管报文交换系统功能架构图</div>
          <div class="architecture-diagram-img">
            <img src="@/assets/manageSystem/jjtg_lct@2x.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 筹资发债管理系统 -->
      <div v-show="tab2" class="proxy-trading-platform">
          <div class="proxy-trading-box">
            <div class="proxy-trading-top">
              <div class="proxy-trading-left"><img src="@/assets/manageSystem/czf_img@2x.png" alt="" /></div>
              <div class="proxy-trading-right">
                <div class="proxy-trading-right-title">筹资发债管理系统</div>
                <div class="proxy-trading-right-content">针对债券发行银行，华炫鼎盛继资金一体化业务系统、流动性管理系统之后，将业务流程延展到资负再平衡的管理上，提供筹资发债管理系统，可以针对发债、承销、银团等筹资业务进行管理。</div>
              </div>
            </div>
          </div>
          
          <div class="bank-efficiency">
                <div class="bank-efficiency-title">应用华炫鼎盛筹资发债管理系统，可以协助银行实现资产负债再平衡的目标：</div>
                <ul>
                <li>
                    <img src="@/assets/manageSystem/czf_img_1@2x.png" alt="" />
                    <div class="secondary-title">快入实现资负平衡目标</div>
                    <div class="secondary-content">汇入华炫鼎盛资金管理以及流动性管理信息之后，可以根据银行短、中、长期战略目标快速找出通过筹资发债实现资产负债平衡的的期限，再根据收益目标建议品种以及利率区间；</div>
                </li>
                <li>
                    <img src="@/assets/manageSystem/czf_img_2@2x.png" alt="" />
                    <div class="secondary-title">完整的筹资发债在线监管</div>
                    <div class="secondary-content">所有的筹资发债过程均在线上通过审批流程实现，让发债过程透明化并得到行内的监管；</div>
                </li>
                <li>
                    <img src="@/assets/manageSystem/czf_img_4@2x.png" alt="" />
                    <div class="secondary-title">费用管理的完整化</div>
                    <div class="secondary-content">筹资发债的费用可占到发行成本数十个BP，系统在每一个业务环节都记录下费用发生的名目和金额，让银行方面了解每一个筹资发债的总成本。</div>
                </li>
                </ul>
            </div>
          <div class="system-advantages-bg">
            <div class="system-advantages">
              <div class="advantages-title">系统优势</div>
              <div class="system-advantages-box">
                <ul>
                  <li>信息完整</li>
                  <li>能导入债券相关发行人、结算中心、承销团、代理人、担保人、评级机构、财务顾问、法律顾问等信息；</li>
                </ul>
                <ul>
                  <li>品种完备</li>
                  <li>可处理金融债、香港债、全球债、固息、浮息、零息、短期、中期、长期等不同债券品种；</li>
                </ul>
              </div>
              <div class="system-advantages-box">
                <ul>
                  <li>模式丰富</li>
                  <li>承销方面包含债券代销、全额包销、余额包销、主承、联合主承、分销等各种发行模式；</li>
                </ul>
                <ul>
                  <li>直通式管理</li>
                  <li>前台的发行、承销、联贷成交的交易发起后，后续的核实、交割，以及存续期内的处理、核算、结算和现金管理，均采用直通式处理。</li>
                </ul>
              </div>
              
            </div>
          </div>
          <div class="proxy-trading-img">
            <div class="proxy-img-title">筹资发债管理系统功能架构图</div>
            <div class="proxy-img"><img src="@/assets/manageSystem/czf_lct@2x.png" alt="" /></div>
          </div>
        </div>
        
      <!-- 流动性管理系统 -->
      <div v-show="tab3" class="proxy-trading-platform">
          <div class="proxy-trading-box">
            <div class="proxy-trading-top">
              <div class="proxy-trading-left"><img src="@/assets/manageSystem/ldx_img@2x.png" alt="" /></div>
              <div class="proxy-trading-right">
                <div class="proxy-trading-right-title">流动性管理系统</div>
                <div class="proxy-trading-right-content">流动性管理是银行最重要的日常工作之一，当各项业务做完之后，可以实时将业务数据汇入华炫鼎盛的流动性管理系统中，进行头寸的分析、头寸的动用、资金的监控与资金的平衡，同时还能针对监管要求提供相应的流动性报表</div>
              </div>
            </div>
            <div class="proxy-trading-center">
              <div class="benefit">应用华炫鼎盛流动性管理系统带给银行以下效益：</div>
              <ul>
                <li>
                  <img src="@/assets/manageSystem/ldx_ico_1@2x.png" alt="" />
                  <div>优化头寸管理模式</div>
                  <span>系统提供头寸计划的管理模式，切实应用该模式可以提高头寸使用的可预见性、提升头寸管理的效率；</span>
                </li>
                <li>
                  <img src="@/assets/manageSystem/ldx_ico_2@2x.png" alt="" />
                  <div>提升资金利用率</div>
                  <span>搭配各种金融商品定价引擎，系统可以提供准确的现金流预测，使银行精准掌握未来数天的头寸余额，进而增加头寸利用率，为银行创造超额收益；</span>
                </li>
                <li>
                  <img src="@/assets/manageSystem/ldx_ico_3@2x.png" alt="" />
                  <div>降低流动性风险</div>
                  <span>提供多档风险指标，当可预见的头寸触及风险指标时进行提示；</span>
                </li>
                <li>
                  <img src="@/assets/manageSystem/ldx_ico_4@2x.png" alt="" />
                  <div>客观的绩效分析提升流动性管理效能</div>
                  <span>系统搭配多维度的流动性管理绩效分析报表,方便银行发现影响流动性管理的问题出在哪项业务、哪个部门或是哪个时段内，方便据以改善，最终提升整体的流动性管理效能。</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="system-advantages-liquidity">
            <div class="system-advantages">
              <div class="advantages-title">系统优势</div>
              <div class="system-content-box">
                <ul>
                  <li>头寸计划</li>
                  <li>各资金需求单位, 按照业务计划与市场指标、政策指标及历史数据相关性分析, 提出各频率的资金计划；</li>
                </ul>
                <ul>
                  <li>头寸汇集</li>
                  <li>提供万能接口汇集资金、债券、同业票据、分支行汇总的存贷汇款等多源头台帐, 智能整理头寸；</li>
                </ul>
              </div>
              <div class="system-content-box">
                <ul>
                  <li>头寸分析与预测</li>
                  <li>透过内部资金缺口与风险值评测及外部经济指标, 政策指标做智能预测分析各天期缺口；</li>
                </ul>
                <ul>
                  <li>智能头寸指令</li>
                  <li>按照头寸分析与预测，系统提供避险与投机金融工具智能建议，可发出单一金融工具指令, 亦可发出头寸指令；</li>
                </ul>
              </div>
              <div class="system-content-box">
                <ul>
                  <li>实时监测头寸</li>
                  <li>所有头寸管理岗位, 对头寸摆布, 汇集信息到头寸监测平台, 并集成支付平台全形, 对于"逼近低水位" 、"透支" 做不同风险级别的警示；</li>
                </ul>
                <ul>
                  <li>报表管理</li>
                  <li>提供灵活的报表工具, 以批量自动产生内部管理报表及监管报表。</li>
                </ul>
              </div>
              
            </div>
          </div>
          <div class="proxy-trading-img">
            <div class="proxy-img-title">流动性管理系统功能架构图</div>
            <div class="proxy-img"><img src="@/assets/manageSystem/ldx_lct@2x.png" alt="" /></div>
          </div>
        </div>
      <!-- 底部通用 -->
      <div class="boxContentFooter">
        <FooterAll />
      </div>
    </div>
  </template>
  <script>
    import Navbar from '../../components/public/NavBar'
    import FooterAll from '../../components/public/footerAll'
    export default {
      components: {
        Navbar,
        FooterAll,
      },
      data(){
        return{
          tab0: true,
          tab1: false,
          tab2: false,
          tab3: false,
          bgColor: 0,
          dataArray1:[
              require('@/assets/partner/cooperate_zf_01@2x.png'),
              require('@/assets/partner/cooperate_zf_02@2x.png'),
              require('@/assets/partner/cooperate_zf_03@2x.png'),
              require('@/assets/partner/cooperate_zf_04@2x.png'),
              require('@/assets/partner/cooperate_zf_05@2x.png')
          ],
          dataArray2:[
            require('@/assets/partner/cooperate_yx_01@2x.png'),
            require('@/assets/partner/cooperate_yx_02@2x.png'),
          ],
        }
      },
      methods: {
        handleTab(v) {
          if(v==0) {
            this.tab0 = true;
            this.tab1 = false;
            this.tab2 = false;
            this.tab3 = false;
            this.bgColor = 0;
          } else if(v==1) {
            this.tab0 = false;
            this.tab1 = true;
            this.tab2 = false;
            this.tab3 = false;
            this.bgColor = 1;
          } else if(v==2) {
            this.tab0 = false;
            this.tab1 = false;
            this.tab2 = true;
            this.tab3 = false;
            this.bgColor = 2;
          } else if(v==3) {
            this.tab0 = false;
            this.tab1 = false;
            this.tab2 = false;
            this.tab3 = true;
            this.bgColor = 3;
          }
        }
      }
    }
  
  </script>
  <style lang="scss" scoped>
  
  @import "../partner/parter.scss";
  .manageSystem {
    background: #FBFBFB;
  }
  .bg-pic{
    background: url('../../assets/manageSystem/gjxt_bj@2x.png')
    no-repeat ;
    background-size: 1920px 320px;
    background-position: 50%;
    min-width: 1180px;
  }
  ul,li{ padding:0;margin:0;list-style:none}
  
  .see-more{
    width: 796px;
    height: 75px;
    padding-top: 24px;
    font-size: 18px;
    color: #ffffff;
    line-height: 26px;
    font-weight: 400;
  }
  .tab-box {
    width: 1519px;
    margin: 0 auto;
    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.05);
    ul {
      display: flex;
      width: 1180px;
      margin: 0 auto;
      li {
        width: 277px;
        height: 63px;
        line-height: 60px;
        margin-right: 25px;
        font-weight: 600;
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        text-align: center;
        cursor: pointer
      }
      li:last-child {
        margin-right: 0px;
      }
      li:hover {
        width: 277px;
        height: 60px;
        background: rgba(0,91,255,0.05);
        border-bottom: 3px solid #005BFF;
      }
    }
    .bg-color {
      width: 277px;
      height: 60px;
      background: rgba(0,91,255,0.05);
      border-bottom: 3px solid #005BFF;
    }
  }
  .tabs-content {
    width: 1180px;
    margin: 0 auto;
  }
  .foreign-exchange-quotation {
    margin-top: 40px;
    .foreign-exchange-top {
      width: 1180px;
      margin: 0 auto;
      height: 352px;
      display: flex;
      img {
        width: 404px;
        height: 352px;
      }
      ul {
        width: 776px;
        background: #FFFFFF;
        padding: 30px 30px 30px 40px;
        li:first-child {
          font-weight: 600;
          font-size: 26px;
          color: #1B314E;
          line-height: 30px;
        }
        li:nth-child(2) {
          font-size: 14px;
          color: #666666;
          line-height: 26px;
          margin-top: 20px;
        }
        li:last-child {
          font-size: 14px;
          color: #666666;
          line-height: 26px;
          margin-top: 20px;
        }
      }
    }
    .system-advantages-img {
      background: url('../../assets/manageSystem/glxt_xtys_bj@2x.png') no-repeat ;
      background-size: 1920px 729px;
      background-position: 50%;
      min-width: 1180px;
      .advantages {
        width: 1180px;
        height: 729px;
        margin: 0 auto;
        .title {
          text-align: center;
          font-weight: 600;
          font-size: 32px;
          color: #1B314E;
          line-height: 38px;
          margin-top: 40px;
          padding-top: 50px;
          padding-bottom: 40px;
        }
      }
    }
    .process-flow {
      width: 1519px;
      height: 620px;
      margin: 0 auto;
      background: linear-gradient( 180deg, rgba(0,89,195,0) 0%, rgba(0,89,195,0.07) 100%);
      .process-flow-title {
        width: 1180px;
        padding-top: 50px;
        padding-bottom: 40px;
        font-weight: 600;
        font-size: 32px;
        color: #1B314E;
        line-height: 38px;
        text-align: center;
      }
      .process-flow-img {
        width: 1180px;
        height: 425px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .quotation-platform-top {
    width: 1180px;
    margin: 0 auto;
    height: 280px;
    display: flex;
    img {
      width: 404px;
      height: 280px;
    }
    ul {
      width: 776px;
      background: #FFFFFF;
      padding: 30px 30px 30px 40px;
      li:first-child {
        font-weight: 600;
        font-size: 26px;
        color: #1B314E;
        line-height: 30px;
      }
      li:nth-child(2) {
        font-size: 14px;
        color: #666666;
        line-height: 26px;
        margin-top: 20px;
      }
      li:last-child {
        font-size: 14px;
        color: #666666;
        line-height: 26px;
        margin-top: 20px;
      }
    }
  }
    .bank-efficiency {
        width: 1519px;
        height: 570px;
        margin: 0 auto;
        background: linear-gradient( 2deg, #ECEFF5 0%, rgba(236,239,245,0) 100%);
        .bank-efficiency-title {
            font-weight: 600;
            font-size: 32px;
            color: #1B314E;
            line-height: 38px;
            text-align: center;
            margin-top: 50px;
            padding-top: 50px;
            padding-bottom: 40px;
        }
        ul {
            display: flex;
            justify-content: space-between;
            width: 1180px;
            margin: 0 auto;
            li {
                width: 368px;
                height: 382px;
                background-color: #FFFFFF;
                box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.09);            
                img {
                    width: 336px;
                    height: 196px;
                    padding: 16px;
                }
                .secondary-title {
                    width: 336px;
                    padding-left: 16px;
                    font-weight: 600;
                    font-size: 16px;
                    color: #0F4994;
                    line-height: 24px;
                    margin-bottom: 10px;
                }
                .secondary-content {
                    width: 336px;
                    padding-left: 16px;
                    line-height: 22px;
                    font-size: 14px;
                    color: #999999;
                }
            }
        }
    }
  .quotation-platform {
      margin-top: 40px;
      .architecture-diagram {
        width: 1519px;
        height: 601px;
        margin: 0 auto;
        background: linear-gradient( 180deg, rgba(0,89,195,0) 0%, rgba(0,89,195,0.07) 100%);
        margin-top: 40px;
        .architecture-diagram-title {
          width: 1180px;
          padding-top: 50px;
          padding-bottom: 40px;
          font-weight: 600;
          font-size: 32px;
          color: #1B314E;
          line-height: 38px;
          text-align: center;
          margin: 0 auto;
        }
        .architecture-diagram-img {
          width: 1180px;
          height: 413px;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  .proxy-trading-platform {
    .proxy-trading-box {
      width: 1180px;
      margin: 0 auto;
    }
    .proxy-trading-top {
      margin-top: 40px;
      width: 100%;
      height: 188px;
      display: flex;
      .proxy-trading-left {
          width: 404px;
          height: 188px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .proxy-trading-right {
        width: 776px;
        background: #FFFFFF;
        padding: 30px 30px 30px 40px;
        .proxy-trading-right-title {
          font-weight: 600;
          font-size: 26px;
          color: #1B314E;
          line-height: 30px;
        }
        .proxy-trading-right-content {
          font-size: 14px;
          color: #666666;
          line-height: 24px;
          margin-top: 20px;
        }
      }
    }
    .proxy-trading-center{
      margin-top: 40px;
      width: 100%;
      height: 402px;
      .benefit {
        font-weight: 600;
        font-size: 24px;
        color: #000000;
        line-height: 28px;
        text-align: center;
        padding-top: 50px;
      }
      ul {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        li {
          width: 277px;
          font-weight: 600;
          font-size: 20px;
          color: #0F4994;
          img {
            display: block;
            width: 50px;
            height: 50px;
            margin-bottom: 24px;
          }
          span {
            display: block;
            width: 277px;
            height: 96px;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 24px;
            margin-top: 10px;
          }
        }
      }
    }
    .system-advantages-bg {
      background: url('../../assets/manageSystem/czf_xtys_bj@2x.png') no-repeat ;
      background-size: 1920px 405px;
      background-position: 50%;
      min-width: 1180px;
      .system-advantages {
        width: 1180px;
        height: 405px;
        margin: 0 auto;
        .advantages-title {
          text-align: center;
          font-weight: 600;
          font-size: 32px;
          color: #1B314E;
          line-height: 38px;
          margin-bottom: 50px;
          padding-top: 50px;
        }
        .system-content-box {
          display: flex;
          margin-left: 87px;
          ul {
            width: 570px;
            height: 104px;
            li:first-child {
              font-weight: 600;
              font-size: 20px;
              color: #0F4994;
              line-height: 23px;
              margin-bottom: 10px;
            }
            li:last-child {
              width: 570px;
              height: 66px;
              font-size: 14px;
              color: #666666;
              line-height: 22px;
            }
          }
          ul:last-child {
            margin-left: 40px;
            li:first-child {
              width: 400px;
            }
            li:last-child {
              width: 400px;
            }
          }
        }
        .system-content-box2 {
          display: flex;
          margin-left: 87px;
          margin-bottom: 36px;
          ul {
            width: 570px;
            height: 60px;
            li:first-child {
              font-weight: 600;
              font-size: 20px;
              color: #0F4994;
              line-height: 23px;
              margin-bottom: 10px;
            }
            li:last-child {
              width: 570px;
              height: 44px;
              font-size: 14px;
              color: #666666;
              line-height: 22px;
            }
          }
          ul:last-child {
            margin-left: 40px;
            li:first-child {
              width: 400px;
            }
            li:last-child {
              width: 400px;
            }
          }
        }
      }
    }
    .system-advantages-liquidity {
      background: url('../../assets/manageSystem/ldx_xtys_bj@2x.png') no-repeat ;
      background-size: 1920px 523px;
      background-position: 50%;
      .system-advantages {
        width: 1180px;
        height: 523px;
        margin: 0 auto;
        .advantages-title {
          text-align: center;
          font-weight: 600;
          font-size: 32px;
          color: #1B314E;
          line-height: 38px;
          margin-bottom: 50px;
          padding-top: 50px;
        }
        .system-content-box {
          display: flex;
          ul {
            width: 570px;
            height: 82px;
            margin-right: 40px;
            margin-bottom: 36px;
            li:first-child {
              font-weight: 600;
              font-size: 20px;
              color: #0F4994;
              line-height: 23px;
              margin-bottom: 10px;
            }
            li:last-child {
              width: 570px;
              height: 44px;
              font-size: 14px;
              color: #666666;
              line-height: 22px;
            }
          }
        }
        .system-content-box:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .proxy-trading-img {
      width: 1519px;
      height: 716px;
      margin: 0 auto;
      background: linear-gradient( 180deg, rgba(0,89,195,0) 0%, rgba(0,89,195,0.07) 100%);
      .proxy-img-title {
        padding-top: 50px;
        padding-bottom: 40px;
        font-weight: 600;
        font-size: 32px;
        color: #1B314E;
        line-height: 38px;
        text-align: center;
      }
      .proxy-img {
        width: 1180px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 521px;
        }
      }
    } 
  }
  
  .system-advantages-box {
        display: flex;
        margin-bottom: 36px;
        ul {
        width: 570px;
        height: 82px;
        li:first-child {
            font-weight: 600;
            font-size: 20px;
            color: #0F4994;
            line-height: 23px;
            margin-bottom: 10px;
        }
        li:last-child {
            width: 570px;
            height: 44px;
            font-size: 14px;
            color: #666666;
            line-height: 22px;
        }
        }
        ul:last-child {
        margin-left: 40px;
        }
    }
  </style>
  